import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-feature-box',
  templateUrl: './feature-box.component.html',
  styleUrls: ['./feature-box.component.css']
})
export class FeatureBoxComponent implements OnInit {

  @Input() image: string
  @Input() title: string
  @Input() detail: string

  constructor() { }

  ngOnInit() {
  }

}
