import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgRedux, select } from '@angular-redux/store'
import { AuthActions } from '../../../store/auth/actions'
import { ContactActions } from '../../../store/actions'
import { IAppState } from '../../../store'
import { HttpClient } from '@angular/common/http'
import { AngularFireDatabase } from 'angularfire2/database'
import { AngularFireAuth } from 'angularfire2/auth'
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  private readonly notifier: NotifierService;
  closeResult = '';
  LOCATION_MSG = 'Setting location is to make sure members working at the office check ' +
    'in and out in the company premises with the member application.'

  query: string = ''
  @select() auth
  position: Object = {}
  location: Object = {}
  form: FormGroup
  loading: boolean = false
  showList: boolean = false
  mapLoading: boolean = false
  user; action; action2; subscription; subscription2; contact


  constructor(private ngRedux: NgRedux<IAppState>, private http: HttpClient,
              private db: AngularFireDatabase, private authentication: AngularFireAuth,
              notifierService: NotifierService, private route: Router, private modalService: NgbModal) {
    this.notifier = notifierService;
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.min(2)]),
      email: new FormControl('', [Validators.required, Validators.email,]),
      location: new FormControl('', []),
      password: new FormControl('', [Validators.required, Validators.min(6),]),
    })
    this.subscription = ngRedux.select('auth').subscribe(item => this.user = item)
    this.subscription2 = ngRedux.select('contact').subscribe(item => this.contact = item)
    this.action = new AuthActions(ngRedux, http, db, authentication)
    this.action2 = new ContactActions(ngRedux, http, db)
    this.position = {lat: 37.0902, lng: 95.7129}
    navigator.geolocation.getCurrentPosition(position => {
      this.position = {lat: position.coords.latitude, lng: position.coords.longitude}
      this.geocode(this.position)
    }, error => console.log(error, 'error'))
  }

  ngOnInit() {}


  get formName() { return this.form.get('name'); }
  get formEmail() { return this.form.get('email'); }
  get formPassword() { return this.form.get('password'); }

  submit = () => {
    if (!this.formName.errors && !this.formEmail.errors && !this.formPassword.errors && !this.loading) {
      this.loading = true
      this.action.login(this.form.value, res => {
        this.loading = false
        if (res !== 'done') this.notifier.notify('error', res);
        else this.route.navigate(['/dashboard'])
      })
    } else console.log(this.form.value)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  showLocationModal = content => {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'modal-holder'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  closeModal = () => {
    // @ts-ignore
    if (this.location.latitude) this.modalService.dismissAll('');
    else this.notifier.notify('error', 'Set company location to continue');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  positionChanged = value => {
    let region = {lat: value.latLng.lat(), lng: value.latLng.lng()}
    this.geocode(region)
  }

  centerChanged = value => {
    this.position = {lat: value.latLng.lat(), lng: value.latLng.lng()}
  }

  geocode = region => {
    this.mapLoading = true
    let geocoder = new google.maps.Geocoder();
    // @ts-ignore
    geocoder.geocode({latLng: region}, res => {
      let locationDetails = res[0].address_components
      let address = res[0].formatted_address, place_id = res[0].place_id
      let city = '', zip = '', country = ''
      locationDetails.map(item => {
        if (item.types[0] === 'locality') city = item.long_name
        if (item.types[0] === 'postal_code') zip = item.long_name
        if (item.types[0] === 'country') country = item.long_name
      }); this.query = address
      this.location = {longitude: region.lng, latitude: region.lat, address, city, zip, country, place_id}
      this.mapLoading = false
    })
  }

  handleChange = (e) => {
    this.query = e.target.value
    if (!this.showList) this.showList = true
    this.action2.suggestLocation(e.target.value)
  }

  selectLocation = location => {
    this.showList = false; this.query = location.description
    let geocoder = new google.maps.Geocoder();
    this.mapLoading = true
    geocoder.geocode({placeId: location.place_id}, res => {
      let locationDetails = res[0].address_components
      let address = res[0].formatted_address, place_id = res[0].place_id
      let city = '', zip = '', country = ''
      locationDetails.map(item => {
        if (item.types[0] === 'locality') city = item.long_name
        if (item.types[0] === 'postal_code') zip = item.long_name
        if (item.types[0] === 'country') country = item.long_name
      })
      this.position = {lng: res[0].geometry.location.lng(), lat: res[0].geometry.location.lat()}
      this.location = {longitude: res[0].geometry.location.lng(),
        latitude: res[0].geometry.location.lat(), address, city, zip, country, place_id}
      this.mapLoading = false
    })
  }

}
