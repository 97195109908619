import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-slide-box',
  templateUrl: './slide-box.component.html',
  styleUrls: ['./slide-box.component.css']
})
export class SlideBoxComponent implements OnInit {

  @Input() url: string
  @Input() detail: string
  @Input() color: string
  @Input() title: string

  constructor() { }

  ngOnInit() {
  }

}
