import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  accordionIndex = 0
  accordion1 = 'Newsec is cloud based solution provided to manage an organization. ' +
    'Newsec offers a better means of managing and interacting with an organization\ ' +
    'members and also a secure and reliable way to manage an organization visitors and vendors.'

  accordion2 = 'Providing secure, efficient alongside quality experience to our customers is our priority. ' +
    'Reliability and safeguarding customer data is our most critical responsibility.'

  accordion3 = 'EFFICIENCY: Raising organizational productivity is the main goal at Newsec. \n' +
    'EXPERIENCE: It\'s all about how the user feels, this is why we provide Seamless means of managing members, visitors and vendors.\n' +
    'PRIVACY: Your privacy is our priority. Security is taken very seriously at Newsec ' +
    'and safeguarding customer data is our most critical responsibility'

  accordion4 = 'Newsec offers features such as, visitor & vendor check-in, location-based sign-in for members, ' +
    'announcement and individual interaction with members, team management and more.'


  slideDetail1 = 'Our contactless solution enables members to check-in and out through their personal device ' +
    'and allows visitors/vendors check-in using the visitors/vendors check-in platform. \n' +
    'This empowers your team to create a safe and secure check-in experience'

  slideDetail2 = 'In addition to basic time and attendance, Newsec Solution provides variety of HR management features such as \n' +
    '- Task Declaration \n- Team Management \n- Announcement and more.'

  slideDetail3 = 'Contactless check-in for visitors and vendors, with smart notification sent to the host. ' +
    'A real-time analytics dashboard to access visitors check-in information. ' +
    'Ensure authenticity & accuracy of visitor data by enabling image capture feature.\n' +
    '\n'

  customOptions: OwlOptions = {
    loop: true, 
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {items: 1},
      400: {items: 2},
      740: {items: 2},
      940: {items: 3}
    },
    nav: true
  }

  slideOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {items: 1},
      400: {items: 2},
      740: {items: 2},
      940: {items: 2}
    },
    nav: true
  }


  constructor() { }

  ngOnInit() {
    document.getElementById('upp').scrollIntoView({behavior: 'smooth'})
  }

  selectAccordion = index => {
    if (this.accordionIndex == index) this.accordionIndex = 0
    else this.accordionIndex = index
  }

}
