import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { NgReduxModule, NgRedux } from '@angular-redux/store'
import { rootReducer, IAppState } from './store'
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http'
import { AppService } from './services/app.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import {GoogleMapsModule} from '@angular/google-maps';
import { HomeComponent } from './container/home/home.component';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from './app.component';
import { HeaderComponent } from './partials/shared/header/header.component';
import { FunctionBoxComponent } from './partials/home/function-box/function-box.component';
import { ServiceBoxComponent } from './partials/home/service-box/service-box.component';
import { StepBoxComponent } from './partials/home/step-box/step-box.component';
import { ContactComponent } from './container/contact/contact.component';
import { InputComponent } from './partials/contact/input/input.component';
import { LoginComponent } from './container/auth/login/login.component';
import { LoginInputComponent } from './partials/login/input/input.component'
import { environment } from 'src/environments/environment';
import {AngularFireModule} from 'angularfire2'
import { AngularFireDatabaseModule } from 'angularfire2/database'
import { AngularFireAuthModule } from 'angularfire2/auth'
import { NotifierModule } from 'angular-notifier';
import { DashboardComponent } from './container/dashboard/dashboard.component';
import { DashHeaderComponent } from './partials/dash-header/dash-header.component';
import { MembersComponent } from './container/dashboard/members/members.component';
import { PrivacyComponent } from './container/policy/privacy/privacy.component';
import { PoliciesComponent } from './container/policy/policies/policies.component';
import { TermsComponent } from './container/policy/terms/terms.component';
import { AccordionComponent } from './partials/home/accordion/accordion.component';
import { SlideBoxComponent } from './partials/home/slide-box/slide-box.component';
import { FeatureBoxComponent } from './partials/home/feature-box/feature-box.component';
import { SignupComponent } from './container/auth/signup/signup.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FunctionBoxComponent,
    ServiceBoxComponent,
    StepBoxComponent,
    ContactComponent,
    InputComponent,
    LoginComponent,
    LoginInputComponent,
    DashboardComponent,
    DashHeaderComponent,
    MembersComponent,
    PrivacyComponent,
    PoliciesComponent,
    TermsComponent,
    AccordionComponent,
    SlideBoxComponent,
    FeatureBoxComponent,
    SignupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.newsec),
    CommonModule,
    CarouselModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    NotifierModule,
    HttpClientModule,
    NgReduxModule,
    NgbModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyAtiKrfivsug43qCftfrr49gGtRVysAg-w', libraries: ['places']}),
    HttpClientJsonpModule,
    GoogleMapsModule
  ],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    ngRedux.configureStore(rootReducer, {})
  }
}
