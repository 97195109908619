import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-login-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})

export class LoginInputComponent implements OnInit {

  @Input() label: string
  @Input() name: string
  @Input() type: string
  @Input() input: boolean
  @Input() model: string
  @Input() form: FormGroup
  @Input() placeholder: string
  @Input() disabled: boolean
  @Input() info: boolean
  @Input() infoTitle: string
  @Input() infoMessage: string
  @Output() clickInfo: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  click = () => {
    this.clickInfo.emit();
  }

}
