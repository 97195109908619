import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

  current: string = 'privacy'

  constructor(public route: Router) { }

  ngOnInit() {
    this.checkRoute()
    document.getElementById('upp').scrollIntoView({behavior: 'smooth'})
  }

  switchTab = tab => this.current = tab

  checkRoute = () => {
    if (this.route.url.includes('privacy')) this.current = 'privacy' 
    else if (this.route.url.includes('terms')) this.current = 'terms'
  }

}
