import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './container/home/home.component';
import { ContactComponent } from './container/contact/contact.component';
import { LoginComponent } from './container/auth/login/login.component'
import { DashboardComponent } from './container/dashboard/dashboard.component'
import { PrivacyComponent } from './container/policy/privacy/privacy.component'
import { PoliciesComponent } from './container/policy/policies/policies.component'
import { SignupComponent } from './container/auth/signup/signup.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'policies', component: PoliciesComponent},
  {path: 'signup', component: SignupComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
